



.menu-closed,.menu-open, .menu-invisible{
   position: absolute; 
   color: black;
   background-color: #DDDDDD; 
   z-index: 2;
   border-radius: 10px;
   top:12vh;
   left:2vh;
   overflow: hidden;
}

.menu-invisible{
   width: 50px;
   height: 50px;
   /* color: rgba(226, 226, 226, 0);  
   background-color: rgba(226, 226, 226, 0); */
   transition: all 0.3s;
}
.menu-open{
    width: 15vw;
    height: 70vh;
    transition: all 0.3s;
}
.menu-closed{
   width: 50px;
   height: 50px;
   transition: all 0.3s;
}


